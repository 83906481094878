import { IConfigsType } from '../../types'

const lfrConfig: IConfigsType = {
  'lfr-global-and-brand-layout-b61d68b9': {
    '/:brand/global-and-brand-layout': {
      groupFilter: {
        api: 'lfrBrandsGroupFilter',
        hide: true,
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'trailing_364_days'],
      },
      systemwideOverview: {
        type: 'list',
        api: 'lfrBusinessReviewKpis',
        title: 'Systemwide Overview',
        width: '120px',
        fields: [
          {
            key: 'grossSales',
            title: 'Gross Sales',
          },
          {
            key: 'grossSalesSssPercent',
            title: 'YOY%',
            decimal: 1,
          },
          {
            key: 'grossSalesBudgetVariance',
            title: 'Gross Sales Var',
            decimal: 1,
          },
          {
            key: 'revPsf',
            title: 'Rev PSF',
          },
          {
            key: 'ppa',
            title: 'Avg Spend',
            decimal: 2,
          },
          {
            key: 'covers',
            title: 'Covers Actual',
          },
          {
            key: 'hourlyLaborCostPercent',
            title: 'Hourly Labor',
            decimal: 1,
          },
          {
            key: 'hourlyLaborBudgetVariance',
            title: 'Hourly Labor Var',
            decimal: 1,
            reverse: true,
          },
          {
            key: 'cogsPercent',
            title: 'COGS Actual',
            decimal: 1,
          },
          {
            key: 'cogsBudgetVariance',
            title: 'COGS Var',
            decimal: 1,
            reverse: true,
          },
          {
            key: 'primeCostsPercent',
            title: 'Prime Cost',
            decimal: 1,
          },
          {
            key: 'primeCostsVariance',
            title: 'Prime Cost Var',
            decimal: 1,
            reverse: true,
          },
          {
            key: 'primeCostsPercentSss',
            title: 'Prime Cost YOY',
            decimal: 1,
            reverse: true,
          },
          {
            key: 'rcpPercent',
            title: 'EBITDA %',
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'lfrListLocationGroupBusinessReview',
        title: 'Breakdown by Brand',
        pageSize: 20,
        defaultSortedField: 'grossSales',
        groupInfo: {
          width: '150px',
          groupBy: {
            width: '150px',
          },
        },
        columns: [
          {
            key: 'Gross Sales',
            title: 'Gross Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'grossSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'revPsf',
                title: 'Rev PSF',
                align: 'center',
                sortable: true,
              },
              {
                key: 'grossSalesBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'grossSalesSssPercent',
                title: 'YOY%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'Comps',
            title: 'Comps',
            align: 'center',
            children: [
              {
                key: 'compsPercent',
                title: 'Comps',
                align: 'center',
                sortable: true,
              },
              {
                key: 'compsVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Covers',
            title: 'Covers',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'covers',
                title: 'Covers',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
              },
              {
                key: 'coversSss',
                title: 'YOY',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Spends',
            title: 'Spends',
            align: 'center',
            children: [
              {
                key: 'ppa',
                title: 'PPA$',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ppaVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
              {
                key: 'ppaSss',
                title: 'YOY',
                align: 'center',
                sortable: true,
                decimal: 2,
              },
            ],
          },
          {
            key: 'COGS',
            title: 'COGS',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'cogsPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'cogsBudgetVariance',
                title: 'Variance',
                align: 'center',
                reverse: true,
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'Hourly Labor',
            title: 'Hourly Labor',
            align: 'center',
            children: [
              {
                key: 'hourlyLaborCostPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'hourlyLaborBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
                reverse: true,
              },
            ],
          },
          {
            key: 'Prime Costs',
            title: 'Prime Costs',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'primeCostsPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'primeCostsVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
                reverse: true,
              },
              {
                key: 'primeCostsPercentSss',
                title: 'YOY',
                align: 'center',
                sortable: true,
                decimal: 1,
                reverse: true,
              },
            ],
          },
          {
            key: 'EBITDA',
            title: 'EBITDA',
            align: 'center',
            children: [
              {
                key: 'rcp',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'rcpPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
              {
                key: 'rcpBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default lfrConfig

import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import * as _ from 'lodash'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { track } from 'pared/analytics/dateRangeSelector'
import Picker from 'pared/components/Picker'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import {
  IAllDateRangeData,
  IDateRange,
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_12_MONTHS,
  TYPE_TRAILING_90_DAYS,
  TYPE_TRAILING_364_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import DialogCloseIcon from 'pared/images/basic/dialog/dialogClose.svg'

interface IProps {
  allDateRangeData: IAllDateRangeData | null
  selectedDateRange: IDateRange | null
  onDateRangeChange: (dateRangeKey: string) => Promise<void>
  isEnabled: boolean
  dateRangeOptions: string[]
  isDailyCustom?: boolean
}

const DATE_DISPLAY_FORMAT = 'M/D/YY'

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Lexend-Regular',
  },
}))

function Main({
  allDateRangeData,
  selectedDateRange,
  onDateRangeChange,
  isEnabled,
  dateRangeOptions,
  isDailyCustom,
}: IProps) {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedYear, setSelectedYear] = useState<number>(
    selectedDateRange ? selectedDateRange.year : 0,
  )
  const [dateRangeType, setDateRangeType] = useState<string>(
    selectedDateRange ? selectedDateRange.type : '',
  )
  const [selectedDateRangeKey, setSelectedDateRangeKey] = useState<string>(
    selectedDateRange ? selectedDateRange.key : '',
  )
  const [selectedStartDate, selectedEndDate] = selectedDateRangeKey.split('-')
  const yesterday = moment().subtract(1, 'days')
  const lastWeek = yesterday.clone().subtract(1, 'weeks')
  const [pickerStartDate, setPickerStartDate] = useState(() =>
    selectedStartDate ? moment(selectedStartDate) : lastWeek,
  )
  const [pickerEndDate, setPickerEndDate] = useState(() =>
    selectedEndDate ? moment(selectedEndDate) : yesterday,
  )
  const [validDateRangeOptions, setValidDateRangeOptions] =
    useState<string[]>(dateRangeOptions)

  const classes = useStyles()

  useEffect(() => {
    if (selectedDateRange) {
      setSelectedYear(selectedDateRange.year)
      setDateRangeType(selectedDateRange.type)
      setSelectedDateRangeKey(selectedDateRange.key)
      const [selectedStartDate, selectedEndDate] =
        selectedDateRange.key.split('-')
      setPickerStartDate(moment(selectedStartDate))
      setPickerEndDate(moment(selectedEndDate))
    }
  }, [selectedDateRange])

  const handleDialogOpen = (event: any) => {
    if (isEnabled) {
      setPopoverAnchorEl(event.currentTarget)
      if (selectedDateRange) {
        setSelectedYear(selectedDateRange.year)
        setDateRangeType(selectedDateRange.type)
        setSelectedDateRangeKey(selectedDateRange.key)
      }
      setIsDialogOpen(true)
    }
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  let selector = null
  let dialogContent = null

  if (allDateRangeData && selectedDateRange) {
    const handleYearChange = (event: any) => {
      const newYear = event.target.value
      const isCurrentYear = moment().year().toString() === newYear.toString()

      track.timeFrameYearChanged(newYear)
      if (dateRangeType === TYPE_QUARTER) {
        const oldDateRange = allDateRangeData.dateRangeMap[selectedDateRangeKey]
        const newQuarters = allDateRangeData.quartersByYear[newYear]
        if (parseInt(`${oldDateRange.index}`) > newQuarters.length) {
          setSelectedDateRangeKey(newQuarters[0].key)
        } else {
          setSelectedDateRangeKey(
            newQuarters[newQuarters.length - parseInt(`${oldDateRange.index}`)]
              .key,
          )
        }
      } else if (dateRangeType === TYPE_PERIOD) {
        const oldDateRange = allDateRangeData.dateRangeMap[selectedDateRangeKey]
        const newPeriods = allDateRangeData.periodsByYear[newYear]
        if (parseInt(`${oldDateRange.index}`) > newPeriods.length) {
          setSelectedDateRangeKey(newPeriods[0].key)
        } else {
          setSelectedDateRangeKey(
            newPeriods[newPeriods.length - parseInt(`${oldDateRange.index}`)]
              .key,
          )
        }
      } else if (
        [TYPE_LAST_WEEK, TYPE_THIS_WEEK, TYPE_WEEK].includes(dateRangeType)
      ) {
        const oldDateRange = allDateRangeData.dateRangeMap[selectedDateRangeKey]
        const newWeeks = allDateRangeData.weeksByYear[newYear]

        if (
          parseInt(`${oldDateRange.index}`) > newWeeks.length ||
          [TYPE_LAST_WEEK, TYPE_THIS_WEEK].includes(dateRangeType)
        ) {
          setSelectedDateRangeKey(newWeeks[0].key)
        } else {
          setSelectedDateRangeKey(
            newWeeks[newWeeks.length - parseInt(`${oldDateRange.index}`)].key,
          )
        }

        if (!isCurrentYear) setDateRangeType(TYPE_WEEK)
      } else {
        setSelectedDateRangeKey(allDateRangeData.yearMap[newYear].key)
        setDateRangeType(TYPE_YEAR)
      }

      if (isCurrentYear) {
        setValidDateRangeOptions(dateRangeOptions)
      } else {
        setValidDateRangeOptions(
          dateRangeOptions.filter((option: string) => {
            return (
              option !== TYPE_YESTERDAY &&
              option !== TYPE_THIS_WEEK &&
              option !== TYPE_LAST_WEEK &&
              option !== TYPE_TRAILING_7_DAYS &&
              option !== TYPE_TRAILING_90_DAYS &&
              option !== TYPE_TRAILING_364_DAYS &&
              option !== TYPE_TRAILING_12_MONTHS
            )
          }),
        )
      }

      setSelectedYear(newYear)
    }

    const handleDateRangeTypeChange = (event: any) => {
      const newType = event.target.value
      track.timeFrameTypeChanged(newType)
      if (newType === TYPE_QUARTER) {
        setSelectedDateRangeKey(
          allDateRangeData.quartersByYear[selectedYear][0].key,
        )
      } else if (newType === TYPE_PERIOD) {
        setSelectedDateRangeKey(
          allDateRangeData.periodsByYear[selectedYear][0].key,
        )
      } else if (newType === TYPE_WEEK) {
        setSelectedDateRangeKey(
          allDateRangeData.weeksByYear[selectedYear][0].key,
        )
      } else if (
        [
          TYPE_LAST_WEEK,
          TYPE_THIS_WEEK,
          TYPE_YESTERDAY,
          TYPE_TRAILING_7_DAYS,
          TYPE_TRAILING_90_DAYS,
          TYPE_TRAILING_364_DAYS,
          TYPE_TRAILING_12_MONTHS,
        ].includes(newType)
      ) {
        setSelectedDateRangeKey(newType)
      } else if (newType === TYPE_CUSTOM) {
        // Do nothing
      } else {
        setSelectedDateRangeKey(allDateRangeData.yearMap[selectedYear].key)
      }
      setDateRangeType(newType)
    }

    const handleDateRangeKeyChange = (event: any) => {
      const dateRangeKey = event.target.value
      track.timeFrameRangeChanged(dateRangeType, dateRangeKey)
      setSelectedDateRangeKey(dateRangeKey)
    }

    const handleLatestPeriodReset = () => {
      const latestPeriod = allDateRangeData.latestPeriod
      if (latestPeriod) {
        track.resetToCurrentPeriodButtonClicked()
        setSelectedYear(latestPeriod.year)
        setValidDateRangeOptions(dateRangeOptions)
        setDateRangeType(TYPE_PERIOD)
        setSelectedDateRangeKey(latestPeriod.key)
      }
    }

    const handleDateRangeChange = async () => {
      setIsDialogOpen(false)
      track.newTimeFrameConfirmed(
        selectedYear,
        dateRangeType,
        selectedDateRangeKey,
      )
      await onDateRangeChange(selectedDateRangeKey)
    }

    const disabledStartDate = (startValue: Moment | undefined) => {
      if (!startValue || !pickerEndDate) {
        return false
      }

      return pickerEndDate.diff(startValue, 'days') < 0
    }

    const disabledEndDate = (endValue: Moment | undefined) => {
      if (!endValue || !pickerStartDate || isDailyCustom) {
        return false
      }

      return endValue.diff(pickerStartDate, 'days') < 0
    }

    const onPickerStartDateChange = (date: Moment) => {
      const dateRangeKey = `${date.format('YYYYMMDD')}-${pickerEndDate.format(
        'YYYYMMDD',
      )}`
      track.timeFrameRangeChanged(dateRangeType, dateRangeKey)
      setPickerStartDate(date)
      setSelectedDateRangeKey(dateRangeKey)
    }

    const onPickerEndDateChange = (date: Moment) => {
      const dateRangeKey = `${(isDailyCustom ? date : pickerStartDate).format(
        'YYYYMMDD',
      )}-${date.format('YYYYMMDD')}`
      track.timeFrameRangeChanged(dateRangeType, dateRangeKey)
      if (isDailyCustom) {
        setPickerStartDate(date)
      }
      setPickerEndDate(date)
      setSelectedDateRangeKey(dateRangeKey)
    }

    let quarterOrPeriodMenu = null
    if (dateRangeType === TYPE_QUARTER) {
      const quarters = allDateRangeData.quartersByYear[selectedYear]
      if (quarters) {
        quarterOrPeriodMenu = quarters.map((quarter) => {
          return (
            <MenuItem key={quarter.key} value={quarter.key}>
              Q{quarter.index} ({quarter.startDate.format(DATE_DISPLAY_FORMAT)}{' '}
              to {quarter.endDate.format(DATE_DISPLAY_FORMAT)})
            </MenuItem>
          )
        })
      }
    } else if (dateRangeType === TYPE_PERIOD) {
      const periods = allDateRangeData.periodsByYear[selectedYear]
      if (periods) {
        quarterOrPeriodMenu = periods.map((period) => {
          return (
            <MenuItem key={period.key} value={period.key}>
              P{period.index} ({period.startDate.format(DATE_DISPLAY_FORMAT)} to{' '}
              {period.endDate.format(DATE_DISPLAY_FORMAT)})
            </MenuItem>
          )
        })
      }
    } else if (dateRangeType === TYPE_WEEK) {
      const weeks = allDateRangeData.weeksByYear[selectedYear]
      if (weeks) {
        quarterOrPeriodMenu = weeks.map((week) => {
          const [period, weekOfPeriod] = `${week.index}`.split('-')
          return (
            <MenuItem key={week.key} value={week.key}>
              P{period} W{weekOfPeriod} (
              {week.startDate.format(DATE_DISPLAY_FORMAT)} to{' '}
              {week.endDate.format(DATE_DISPLAY_FORMAT)})
            </MenuItem>
          )
        })
      }
    } else {
      quarterOrPeriodMenu = (
        <MenuItem value={selectedDateRangeKey}>
          <DisabledMenuItemDiv>---</DisabledMenuItemDiv>
        </MenuItem>
      )
    }

    selector = (
      <MainSelect
        open={false}
        value={selectedDateRange.key}
        displayEmpty
        disabled={!isEnabled}
        onClick={handleDialogOpen}
        className={classes.text}
      >
        {isEnabled ? (
          <MenuItem value={selectedDateRange.key} className={classes.text}>
            {(() => {
              switch (selectedDateRange.type) {
                case TYPE_YEAR:
                  return `Year ${selectedDateRange.year}`
                case TYPE_WEEK:
                  const [period, weekOfPeriod] =
                    `${selectedDateRange.index}`.split('-')
                  return `P${period} W${weekOfPeriod} (${selectedDateRange.startDate.format(
                    DATE_DISPLAY_FORMAT,
                  )} to ${selectedDateRange.endDate.format(
                    DATE_DISPLAY_FORMAT,
                  )})`
                case TYPE_LAST_WEEK:
                case TYPE_THIS_WEEK:
                case TYPE_YESTERDAY:
                case TYPE_TRAILING_7_DAYS:
                case TYPE_TRAILING_90_DAYS:
                case TYPE_TRAILING_364_DAYS:
                case TYPE_TRAILING_12_MONTHS:
                  return (
                    allDateRangeData?.dateRangeMap?.[selectedDateRange?.type]
                      ?.displayName ||
                    selectedDateRange.type
                      .split(/_/)
                      .map(_.upperFirst)
                      .join(' ')
                  )
                case TYPE_CUSTOM:
                  if (isDailyCustom) {
                    return selectedDateRange.endDate.format(DATE_DISPLAY_FORMAT)
                  }

                  return `${selectedDateRange.startDate.format(
                    DATE_DISPLAY_FORMAT,
                  )} to ${selectedDateRange.endDate.format(
                    DATE_DISPLAY_FORMAT,
                  )}`
                default:
                  return [
                    selectedDateRange.type === TYPE_QUARTER ? 'Q' : 'P',
                    selectedDateRange.index,
                    ' (',
                    selectedDateRange.startDate.format(DATE_DISPLAY_FORMAT),
                    ' to ',
                    selectedDateRange.endDate.format(DATE_DISPLAY_FORMAT),
                    ')',
                  ].join('')
              }
            })()}
          </MenuItem>
        ) : (
          <MenuItem value={selectedDateRange.key} className={classes.text}>
            <DisabledMenuItemDiv>---</DisabledMenuItemDiv>
          </MenuItem>
        )}
      </MainSelect>
    )

    dialogContent = (
      <DialogContent>
        <DialogTitle>Select a Time Frame</DialogTitle>

        <StyledIconButton aria-label="close" onClick={handleDialogClose}>
          <img src={DialogCloseIcon} alt="close" />
        </StyledIconButton>

        <SelectFormControl variant="outlined">
          <Select value={selectedYear} onChange={handleYearChange} displayEmpty>
            {allDateRangeData.years.map((year) => {
              return (
                <MenuItem key={year.key} value={year.year}>
                  {year.year}
                </MenuItem>
              )
            })}
          </Select>
        </SelectFormControl>

        <SelectFormControl variant="outlined">
          <Select
            value={dateRangeType}
            onChange={handleDateRangeTypeChange}
            displayEmpty
          >
            {validDateRangeOptions.map((value) => (
              <MenuItem key={value} value={value}>
                {allDateRangeData?.dateRangeMap?.[value]?.displayName ||
                  value.split(/_/).map(_.upperFirst).join(' ')}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>

        {dateRangeType === TYPE_CUSTOM ? (
          <>
            {isDailyCustom ? null : (
              <SelectFormControl>
                <div>Start Date</div>
                <Picker
                  onChange={onPickerStartDateChange}
                  disabledDate={disabledStartDate}
                  value={pickerStartDate}
                />
              </SelectFormControl>
            )}
            <SelectFormControl>
              <div>{isDailyCustom ? 'Date' : 'End Date'}</div>
              <Picker
                onChange={onPickerEndDateChange}
                disabledDate={disabledEndDate}
                value={pickerEndDate}
              />
            </SelectFormControl>
          </>
        ) : null}

        <SelectFormControl variant="outlined">
          <Select
            value={selectedDateRangeKey}
            onChange={handleDateRangeKeyChange}
            displayEmpty
            disabled={[
              TYPE_YEAR,
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_YESTERDAY,
            ].includes(dateRangeType)}
          >
            {quarterOrPeriodMenu}
          </Select>
        </SelectFormControl>

        {dateRangeOptions.includes(TYPE_PERIOD) ? (
          <ResetToLatestPeriodDiv>
            <ResetToLatestPeriodSpan onClick={handleLatestPeriodReset}>
              Reset to Current Period
            </ResetToLatestPeriodSpan>
          </ResetToLatestPeriodDiv>
        ) : null}

        <ConfirmButtonContainer>
          <ConfirmButton onClick={handleDateRangeChange}>CONFIRM</ConfirmButton>
        </ConfirmButtonContainer>
      </DialogContent>
    )
  } else {
    selector = (
      <Loading
        placeholder="Loading..."
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }}
        disabled
      />
    )
  }

  return (
    <>
      <LargeScreen>
        <LargeScreenFormControl
          variant="outlined"
          margin="dense"
          style={{ width: 260 }}
        >
          {selector}

          <Popover
            open={isEnabled && isDialogOpen}
            onClose={handleDialogClose}
            anchorEl={popoverAnchorEl}
            anchorOrigin={{ vertical: 50, horizontal: -250 }}
          >
            <PopoverContentContainer>{dialogContent}</PopoverContentContainer>
          </Popover>
        </LargeScreenFormControl>
      </LargeScreen>
      <SmallScreen>
        <SmallScreenFormControl
          variant="outlined"
          margin="dense"
          style={{ width: '100%' }}
        >
          {selector}

          <Dialog
            fullWidth
            open={isEnabled && isDialogOpen}
            onClose={handleDialogClose}
          >
            {dialogContent}
          </Dialog>
        </SmallScreenFormControl>
      </SmallScreen>
    </>
  )
}

const LargeScreenFormControl = styled(FormControl)`
  background-color: white;
`

const MainSelect = styled(Select)`
  border-radius: 0;
`

const SmallScreenFormControl = styled(FormControl)`
  background-color: white;
`

const DisabledMenuItemDiv = styled.div`
  width: 100%;
  text-align: center;
`

const PopoverContentContainer = styled.div`
  width: 500px;
`

const DialogContent = styled.div`
  position: relative;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  padding: 50px 0 45px 0;
  text-align: center;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const DialogTitle = styled.div`
  width: 100%;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 30px 0;
  text-align: center;
`

const SelectFormControl = styled(FormControl)`
  width: 80%;
  margin: 15px auto;
`

const Loading = styled(TextField)`
  font-family: Lexend-Regular;

  .MuiInputBase-root {
    border-radius: 0;
  }
`

const ResetToLatestPeriodDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 5px 0 0 0;
`

const ResetToLatestPeriodSpan = styled.span`
  font-family: Lexend-Regular;
  color: #07a4c7;
  text-decoration: underline;
  cursor: pointer;
`

const ConfirmButtonContainer = styled.div`
  width: 100%;
  padding: 35px 0 0 0;
  text-align: center;
`

const ConfirmButton = styled.button`
  background-color: #07a4c7;
  border: none;
  color: white;
  padding: 15px 15px 15px 15px;
  width: 250px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
`

export default Main
